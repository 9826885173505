<template>
    <v-col cols="12" class="pt-0 px-0">
        <v-card min-width="100%" class="mb-6">
            <v-toolbar :color="color"  :style="`color: ${textColor}`" elevation="3" class="scan-title-bar">
                <v-toolbar-title class="text-section-title">Communication</v-toolbar-title>
            </v-toolbar>
            <SendMail :color="color" :style="`color: ${textColor}`" el="detection-section" @toogleAdd="$emit('selectEnable', $event)" :attached="attachments" :loading="loading"
                @submit="sendMailData" :tempList="templatesList"/>
        </v-card>
    </v-col>
</template>

<script>
import { SendMail } from "@/components/common";
import { mapActions } from 'vuex';

export default {
    props: ['selectedImages', 'color', 'textColor'],
    emits: [ 'selectEnable' ],
    components: { SendMail },
    data: (vm) => ({
        scanId: vm.$route.params.scanId,
        loading: false,
        attachments: [],
        templatesList: []
    }),
    watch: {
        'selectedImages': {
            handler: function(value) {
                this.generateAttached(value);
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        this.generateAttached(this.selectedImages);
        this.getTempList();
    },
    methods: {
        ...mapActions([ 'sendMailTo', 'getModelList']),

        getTempList() {
            this.getModelList('emailTemplates').then(resp => this.templatesList = resp || []);
        },
        generateAttached(value) {
            this.attachments = value.damages.concat(value.annotates);
            if (value.pdf) this.attachments.push(...value.pdf);
        },
        sendMailData (data) {
            this.loading = true;
            if(this.scanId) data.scan_id = this.scanId;
            this.sendMailTo(data).then((response) => this.loading = false).catch(err => this.loading = false);
        }
    }
}
</script>

<style>

</style>